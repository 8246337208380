import React from 'react';

class PrintUtil extends React.Component {

  static printLabel(zebra_label_path, dymo_label_path, params, copies = 1) {
    return new Promise(async (resolve, reject) => {

      try {
        await this.printZebraLabel(zebra_label_path, params, copies);
        resolve();
      } catch (zebra_error) {
        console.log(zebra_error);

        try {
          await this.printDymoLabel(dymo_label_path, params, copies);
          resolve();
        } catch (dymo_error) {
          console.log(dymo_error);
          reject(<span>Zebra: {zebra_error}<br/>DYMO: {dymo_error}</span>);
        }
      }
    });
  }

  // DYMO

  static async printDymoLabel(label_path, params, copies) {
    var printer = await this.getDymoPrinter();
    var data = await this.getLabelFile(label_path);
    await this.dymoPrint(printer, data, params, copies);
  }

  static getDymoPrinter() {
    return new Promise((resolve, reject) => {     
      try {
        dymo.label.framework.getPrintersAsync().then((printers) => {
          if (!printers || printers.length == 0) {
            reject("No DYMO printers found");
            return;
          }

          var avaiablePrinter;
          for(const printer of printers) {
            if(printer.isConnected) {
              avaiablePrinter = printer;
            }
          }
          
          if(!avaiablePrinter) {
            reject("DYMO printer is not connected");
            return;
          }

          resolve(avaiablePrinter);
        }).thenCatch((error) => {
          console.log(error);
          reject("Unable to connect to DYMO printer software");
        });
      } catch (error) {
        console.log(error);
        reject("Unable to connect to DYMO printer software");
      }
    });
  }

  static dymoPrint(printer, xml, params, copies) {
    return new Promise((resolve, reject) => {
      try {
        var labelXml = dymo.label.framework.openLabelXml(xml);
        
        var builder = new dymo.label.framework.LabelSetBuilder();
        let labelRecord = builder.addRecord();
        for (let [key, value] of params) {
          labelRecord.setText(key, value);
        }
        
        var paramsXml = dymo.label.framework.createLabelWriterPrintParamsXml({ copies: copies });

        dymo.label.framework.printLabel(printer.name, paramsXml, labelXml, builder.toString());
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  // Zebra

  static async printZebraLabel(label_path, params, copies) {
    var printer = await this.getZebraPrinter();
    var data = await this.getLabelFile(label_path);
    await this.zebraPrint(printer, data, params, copies);
  }
  
  static getZebraPrinter() {
    return new Promise((resolve, reject) => {
      try {
        BrowserPrint.getLocalDevices(function(result) {
          if (!result || !result.printer || result.printer.length == 0) {
            reject("No Zebra printers found");
            return;
          }

          resolve(result.printer[0]);
        }, function(error) {
          console.error(error);
          reject("Unable to connect to Zebra printer software");
        });
      } catch (error) {
        console.error(error);
        reject("Unable to connect to Zebra printer software");
      }
    });
  }

  static zebraPrint(printer, data, params, copies) {
    return new Promise((resolve, reject) => {
      try {
        data = data.replace(`{{COPIES}}`, copies)
        for (let [key, value] of params) {
          data = data.replace(`{{${key}}}`, value)
        }
        
        printer.send(data, function() { resolve(); }, function(error) { reject(error); } );
      } catch (error) {
        reject(error);
      }
    });
  }

  // Generic

  static getLabelFile(label_path) {
    return new Promise((resolve, reject) => {
      var noCacheHeaders = new Headers();
      noCacheHeaders.append('pragma', 'no-cache');
      noCacheHeaders.append('cache-control', 'no-cache');
      
      var fetchParams = {
        method: 'GET',
        headers: noCacheHeaders,
      };

      fetch(label_path, fetchParams).then(content => {
        content.text().then(data => {
          localStorage.setItem(label_path, data);
          resolve(data);
        });
      }).catch((error) => {
        console.log(error);
        var data = localStorage.getItem(label_path);
        if (data) {
          resolve(data);
        } else {
          console.error(`Label file ${label_path} not found`);
          reject("Label file not found");
        }
      });
    });
  }
}

export default PrintUtil;
